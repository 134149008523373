/* Display abbreviations */

.d-none {
  display: none;
}

/* Flexbox abbreviations */

.f-row {
  display: flex;
}

.f-row-center {
  display: flex;
  justify-content: center;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-col {
  display: flex;
  flex-direction: column;
}

.f-col-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.f-col-c-100 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.f-gs-auto {
  flex: 1 1 auto;
}

.f-gs-50 {
  flex: 1 1 50%;
}

.f-s-auto {
  flex: 0 1 auto;
}

.f-none {
  flex: none;
}

/* Grid abbreviations */

.g-2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* Overflow abbreviations */

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

/* Position abbreviations */

.p-relative {
  position: relative;
}

/* Helpers */

.nowrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}
