.DialogBase {
  opacity: 0;
  pointer-events: none;
}

.DialogBase.DialogBase-isOpen {
  opacity: 1;
  pointer-events: auto;
}

.DialogBackdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 300ms;
}

.DialogBackdrop.DialogBackdrop-isMounted {
  display: block;
}

.DialogBackdrop.DialogBackdrop-isVisible {
  opacity: 1;
  pointer-events: auto;
}
