$color-primary: #194983;
$color-highlight: #d97845;

h3.highlight {
  color: $color-highlight;
  font-size: 18px;
}

button {
	background-color: transparent;
}

input, select {
  border: 1.5px solid $color-primary;
  border-radius: 8px;
  padding: 13px 14px 14px 12px;
  font-size: 16px;
}

input[type=radio]:focus ~ svg {
  background: transparent;
}

#case_study_slider .slick-dots li button:before {
  font-size: 12px;
}

#case_study_slider .slick-dots {
  bottom: 15px;
}

#case_study_slider .slick-prev:before, #case_study_slider .slick-next:before {
  color: $color-primary;
  font-size: 40px;
}

#case_study_slider .slick-prev, #case_study_slider .slick-next {
  height: 40px;
  width: 40px;
  bottom: -17px;
  top: auto;
  z-index: 100;
}

#case_study_slider .slick-prev {
  left: calc(50% - 85px);
}

#case_study_slider .slick-next {
  right: calc(50% - 85px);
}

#case_study_slider .slick-list,
#case_study_slider .slick-track,
#case_study_slider .slick-slide > div {
  height: 100%;
  max-height: inherit;
}

#case_study_slider.lesinski_case .slick-prev {
  left: calc(50% - 100px);
}

#case_study_slider.lesinski_case .slick-next {
  right: calc(50% - 96px);
}

#case_study_slider.rimm_case .slick-prev {
  left: calc(50% - 120px);
}

#case_study_slider.rimm_case .slick-next {
  right: calc(50% - 115px);
}

.formField-radioButton {
    svg {
        fill: #194983;
    }
}
