fieldset {
  border: none;
}

label {
  margin: 0 0 4px 4px;
}

input, select {
  padding: 13px 14px 14px 12px;
  font-size: 16px;
}

select {
  background: white;
  width: 100%;
  appearance: none;
}

.formField.isError input, .formField.isError select {
  border-color: red !important;
}

.formErrorMessage {
  color: #8D3636;
  background-color:  #F2DEDE;

  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  width: fit-content;
  height: 19px;
  padding: 2px 2px 0 2px;
  margin: 4px 3px -23px 4px;

  opacity: 0;
  transition: opacity 300ms;
}

.formErrorMessage.isError {
  opacity: 1;
}

.formField-checkbox .formErrorMessage {
  white-space: normal;
  margin: 0;
  padding: 0;
}

.formField-checkbox.isError .formErrorMessage {
  opacity: 1;
}
