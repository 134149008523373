@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Book.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham';
  src: url('./GothamMedium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-BookItalic.otf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Medium.otf');
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Bold.otf');
  font-weight: 700;
  font-display: block;
}
