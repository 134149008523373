@import "./abbrevs";

*,::before,::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: 0 none;
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/* Animations */

.fade-in {
  opacity: 0;
  transition: opacity 300ms;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes dashoffset-to-zero {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes translate-to-origin {
  to {
    transform: translate(0, 0);
  }
}
